/** @module store
 *  @since 2022.01.22, 22:22
 *  @changed 2022.03.29, 21:22
 */

import create from 'zustand'

import { AlertStatus, createStandaloneToast } from '@chakra-ui/react'

import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import Requestor from '@helpers/Requestor'
import config from '@config'
// import firebaseConfig from './firebaseConfig'

// TODO: Extract to separated module.
export let firebaseApp
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(config.firebase)
  // console.log('XXX', firebaseApp)
  // debugger
}

export type TState = {
  initApp: () => Promise<void>
  initAnalytics: () => Promise<void>
  analytics: firebase.analytics.Analytics | undefined
  firestore: firebase.firestore.Firestore
  isNavOverlayOpen: boolean
  setNavOverlayOpen: (isOpen: boolean) => void
  appInited: boolean
  Token?: number | string
  ipAddr?: string
  // TODO: apiVersion
}

interface TShowToastProps {
  title: string
  description: string
  status?: AlertStatus
}
function showToast({ title, description, status }: TShowToastProps) {
  const toast = createStandaloneToast()
  toast({
    title: title,
    description: description,
    status: status || 'error',
    position: 'top',
    duration: 5000,
    isClosable: true,
  })
}

const useStore = create<TState>((set, get) => ({
  analytics: undefined,
  // TODO: initApp
  appInited: false,
  Token: null,
  ipAddr: null,
  initApp: async function initApp() {
    // Make initional requiest to firebase cloud app...
    try {
      const { firebase } = config
      const { location, projectId, appId } = firebase
      // TODO: Store app url in config? Generate it with api fabric?
      // const url = 'https://' + location + '-' + projectId + '.cloudfunctions.net/app/init'
      const url = config.api.apiUrlPrefix + '/start'
      // @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
      const fetchOptions = {
        // mode: 'no-cors' as RequestMode, // eslint-disable-line no-undef
        headers: {
          Accept: 'application/json',
        },
      }
      const requestor = new Requestor({ id: 'store' })
      // console.log('@:store:initApp: fetch start', {
      //   url,
      //   fetchOptions,
      //   location,
      //   projectId,
      //   appId,
      //   firebase,
      // })
      // debugger
      // fetch(url, fetchOptions) // OLD_CODE: Using fetch
      requestor
        .fetch({ url })
        // .then((res) => res.json())
        .then((data: { Token: string; ip: string }) => {
          // const { ok, body, status } = res
          const { Token, ip: ipAddr } = data
          // eslint-disable-next-line no-console
          console.log('@:store:initApp: fetch done', {
            url,
            Token,
            ipAddr,
            data,
          })
          // debugger
          if (!Token || !ipAddr) {
            const error = new Error('Got invalid initalization parameters.')
            // eslint-disable-next-line no-console
            console.error('@:store:initApp:error', String(error), {
              error,
              Token,
              ipAddr,
              data,
              // Params...
              url,
              location,
              projectId,
              appId,
            })
            // eslint-disable-next-line no-debugger
            debugger
          }
          set({ appInited: true, Token, ipAddr })
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('@:store:initApp: fetch catch', {
            error,
            // Source params...
            url,
            fetchOptions,
            location,
            projectId,
            appId,
            firebase,
          })
          // eslint-disable-next-line no-debugger
          debugger
          // throw error
          // Show toast...
          showToast({
            title: 'Неудачная попытка установки связи с сервером',
            description: String(error),
          })
        })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('@:store:initApp: catch', {
        error,
      })
      // eslint-disable-next-line no-debugger
      debugger
      // Show toast...
      showToast({
        title: 'Ошибка инициализации связи с сервером',
        description: String(error),
      })
    }
  },
  initAnalytics: async function initAnalytics() {
    // Start firebase analitics...
    if (!get().analytics) {
      let isSupported: boolean
      try {
        isSupported = config.site.useExternalAnalytics && (await firebase.analytics.isSupported())
      } catch (error) {
        isSupported = false
      }
      if (config.firebase.measurementId && isSupported) {
        const analytics = firebase.analytics()
        // console.log('@:store:useStore:initAnalytics', {
        //   'config.firebase': config.firebase,
        //   isSupported,
        //   firebase,
        //   analytics,
        //   firebaseApp,
        // })
        set({ analytics })
      }
    }
  },
  firestore: firebase.firestore(),
  isNavOverlayOpen: false,
  setNavOverlayOpen: (isOpen) => set({ isNavOverlayOpen: isOpen }),
}))

export default useStore
