/** @module _app.js
 *  @since 2021.12.30, 02:18
 *  @changed 2022.01.22, 18:20
 */

import * as React from 'react'
import { AppProps } from 'next/app'

import '@config'

import '@global/generic-includes'
import AppWrapper from '@global/AppWrapper'

import './global-styles.less'

function NextApp(props: AppProps) {
  const { Component, pageProps } = props
  return (
    <AppWrapper>
      <Component {...pageProps} />
    </AppWrapper>
  )
}

export default NextApp
