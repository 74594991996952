/** @module setup-chakra
 *  @since 2022.01.03, 17:48
 *  @changed 2022.02.18, 18:50
 */

// @see https://chakra-ui.com/docs/theming/theme
// @see https://chakra-ui.com/docs/theming/advanced

import { extendTheme } from '@chakra-ui/react'
import cssParams from '@global/cssParams'
// import config from '@config'
// import * as colorUtils from '@utils/colors'

const {
  brandColors,
  primaryColors,
  secondaryColors,
  neutralColors, // ???
} = cssParams

/* // Generated color results on 2022.01.03:
 * brand: {100: '#ffffff', 200: '#c266ff', 300: '#ad33ff', 400: '#a31aff', 500: '#9900ff', 600: '#8a00e6', 700: '#7a00cc', 800: '#6b00b3', 900: '#5c0099'}
 * primary: {100: '#ffffff', 200: '#c266ff', 300: '#ad33ff', 400: '#a31aff', 500: '#9900ff', 600: '#8a00e6', 700: '#7a00cc', 800: '#6b00b3', 900: '#5c0099'}
 */

/* // DEBUG: Test example & generated color sets...
 * const greenBaseColor = '#0ca25f' // cssParams.greenColor
 * // DEBUG: Generated
 * const greenGenerated = createColorVariants(greenBaseColor)
 * // DEBUG: Example
 * const greenExample = {
 *   100: '#e5fcf1',
 *   200: '#27ef96',
 *   300: '#10de82',
 *   400: '#0ebe6f',
 *   500: '#0ca25f',
 *   600: '#0a864f',
 *   700: '#086f42',
 *   800: '#075c37',
 *   900: '#064c2e'
 * }
 * // Check generated and example green color variants...
 * console.log('@:setup-chakra-theme: test example', {
 *   greenBaseColor,
 *   greenGenerated,
 *   greenExample,
 *   // customTheme,
 *   // css: (typeof config !== 'undefined') ? cssParams : null,
 * })
 */

const colors = {
  // greenGenerated,
  // greenExample,
  brand: brandColors,
  primary: primaryColors,
  secondary: secondaryColors,
  neutral: neutralColors,
}

/* // UNUSED: breakpoints example v.0
 * const breakpoints = [[>sm:22.5em<]'360px', [>md:48em<]'768px', [>lg:64em<]'1024px', [>90em<]'1440px']
 */
/* // UNUSED: breakpoints v.2
 * const breakpoints = {
 *   'base': '30em',
 *   'sm': '48em',
 *   'md': '64em',
 *   'lg': '72em',
 *   'xl': '80em',
 * }
 */
/* // UNUSED: breakpoints v.2
 * const breakpoints = {
 *   'base': '0em', // '0em',
 *   'sm': '360px', // '30em',
 *   'md': '768px', // '48em',
 *   'lg': '1024px', // '62em',
 *   'xl': '1200px', // '80em',
 *   // '2xl': '1600px', // '96em',
 * }
 */
// TODO: Store in cssParams?
const breakpoints = cssParams.breakpointsPx
// const breakpoints = {
//   base: '360px', // '30em',
//   sm: '512px',
//   md: '768px', // '48em',
//   lg: '1024px', // '64em',
//   xl: '1280px', // '80em',
//   '2xl': '1600px', // '100em',
// }

const components = {
  // TODO: Input: focusBorderColor, errorBorderColor for light/dark modes
  Checkbox: {
    defaultProps: {
      colorScheme: 'primary',
    },
  },
  Heading: {
    variants: {
      primary: ({ colorScheme = 'primary' }) => ({
        color: colorScheme + '.500',
      }),
    },
  },
  Link: {
    variants: {
      primary: ({ colorScheme = 'primary' }) => ({
        color: colorScheme + '.500',
        _hover: {
          color: colorScheme + '.700',
        },
      }),
    },
    defaultProps: {
      variant: 'primary',
    },
  },
}
const customTheme = extendTheme({
  colors,
  breakpoints,
  components,
})

/* console.log('@:setup-chakra-theme', {
 *   // primaryColors,
 *   // secondaryColors,
 *   // neutralColors,
 *   colors,
 *   components,
 *   customTheme,
 * })
 */

export default customTheme
