/** @module config.app
 *  @since 2019.09.10, 14:25
 *  @changed 2021.05.14, 15:33
 */

import * as build from './build'

export const DEBUG = build.DEBUG

/** Date conversion & presentation templates... */

export const useHideableNavBar = false
export const showTopSplash = false
export const showTopCarousel = true
