/** @module index
 *  @since 2021.12.27, 18:31
 *  @changed 2022.01.03, 23:56
 */

import * as React from 'react'
// import { AppProps } from 'next/app'

// Chakra...
import {
  ChakraProvider,
  // ThemeProvider,
  // ColorModeProvider,
  // theme,
  CSSReset,
} from '@chakra-ui/react'

import config from '@config'

import useStore from '@global/store'

import customTheme from '@global/setup-chakra-theme'

function StoreWrapper({ children }: { children: JSX.Element }) {
  const [initApp, initAnalytics] = useStore((state) => [state.initApp, state.initAnalytics])
  React.useEffect(() => {
    if (!config.build.isDev) {
      initAnalytics()
    }
    initApp()
  }, [initApp, initAnalytics])
  return children
}

function AppWrapper({ children }: { children: JSX.Element }) {
  return (
    <ChakraProvider theme={customTheme}>
      <CSSReset />
      <StoreWrapper>{children}</StoreWrapper>
    </ChakraProvider>
  )
}

export default AppWrapper
