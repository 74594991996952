/** @module config.site
 *  @since 2022.01.03, 16:27
 *  @changed 2022.02.08, 20:14
 */

import * as build from './build'

export const titleDelim = ' — '
export const siteUrl = 'https://march.team/'
export const title = 'Компания «Март-Недвижимость»'
// @see https://yandex.ru/support/webmaster/indexing-options/description.html
export const descr =
  'Продаем квартиры в новостройках по цене застройщика. Только у нас дополнительные услуги от наших партнёров: налоговое консультирование, переезд, ремонт, страхование.'
export const keywords = [
  'квартира',
  'коттедж',
  'жилой комплекс',
  'апартаменты',
  'недвижимость',
  'новостройки',
  'застройщик',
  'ипотека',
  'консультация',
  'аренда',
  'покупка',
  'продажа',
  'переезд',
  'ремонт',
  'страхование',
  'сделка',
]
export const faviconUrl = '/static/favicon.ico'
export const faviconPngUrl = '/static/favicon-120x120.png'
export const faviconSvgUrl = '/static/favicon.svg'
export const opImageUrl = '/static/logo-256.png'
export const opImageWidth = 256
export const opImageHeight = 256

export const phoneNumber = '+7 (995) 790 70 10'
export const emailAddress = 'mail@march.team'
export const emailAddress2 = 'march.realty@mail.ru'

// see also: `src/config/links.ts:socials`
export const instagramUrl = 'https://www.instagram.com/march.realty/'
export const instagramName = '@march.realty'

export const yandexMetricId = 87348711 // 87056667 // 87322577
export const useExternalAnalytics = true && build.isBrowser && !build.isDev
