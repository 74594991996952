/** @module RequestError
 *  @since 2021.12.22, 14:44
 *  @changed 2022.01.24, 18:29
 */

export interface TRequestErrorParams {
  name?: string // 'RequestError'
  message: string // 'Request processing error (' + status + ')',
  url?: string // requestUrl,
  data?: any
  headers?: any
  method?: string // 'GET', 'POST'
  status?: number // 404
  reason?: string // Custom idendtifier, 'InvalidResponse',
}

class RequestError extends Error {
  name = 'RequestError'
  message: string // 'Request processing error (' + status + ')'
  url?: string // requestUrl
  data?: any
  headers?: any
  method?: string // 'GET', 'POST'
  status?: number // 404
  reason?: string // Custom idendtifier, 'InvalidResponse'

  constructor(params: TRequestErrorParams) {
    super(params.message)
    Object.keys(params).forEach((key) => {
      if (params[key] != null) {
        this[key] = params[key]
      }
    })
  }
}

export default RequestError
