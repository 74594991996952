/** @module config
 *  @description App config
 *  @since 2019.08.29, 10:28
 *  @changed 2022.01.22, 18:25
 */

import * as api from './api'
import * as app from './app'
import * as build from './build'
import * as constants from './constants'
import * as firebase from './firebase'
import * as links from './links'
import * as site from './site'

const config = {
  api,
  app,
  build,
  constants,
  firebase,
  links,
  site,
}

// Expose config as global variable. (Deprecated?)
if (typeof global !== 'undefined') {
  global.config = config
} else if (typeof window !== 'undefined') {
  window.config = config
}

export default config
