/** @module config.links
 *  @since 2022.01.15, 20:38
 *  @changed 2022.02.11, 20:58
 */

import {
  faVk,
  faFacebookSquare,
  faInstagram,
  // faTwitterSquare,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons'
// NOTE: Other icon bundles:
// import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
// import { faEnvelopeSquare } from '@fortawesome/free-regular-svg-icons'

export enum LocationHashEnum {
  /* // Old locations
   * LocationAbout = 'about',
   * Services = 'services',
   * LocationCommand = 'ourcommand',
   * LocationProjects = 'ourprojects',
   * Sponsors = 'sponsors',
   * Challenges = 'challenges',
   * LocationFAQ = 'faq',
   */
  LocationCatalogue = 'catalogue',
  LocationOffers = 'offers',
  LocationServices = 'services',
  LocationPartners = 'partners',
  LocationForPartners = 'forpartners',
  LocationForRegions = 'forregions',
  LocationContacts = 'contacts',
  // LocationBlogs = 'blogs',
  // LocationSocials = 'socials',
}

export const locations: { href?: string; label: string }[] = [
  { href: '/benefits', label: '7 причин, почему выгодно покупать квартиру с нами' },
  // { href: '#' + LocationHashEnum.LocationCatalogue, label: 'Каталог недвижимости' },
  // { href: '#' + LocationHashEnum.LocationCatalogue, label: 'Каталог недвижимости' },
  // { /* href: '#' + LocationHashEnum.LocationOffers, */ label: 'Актуальные предложения' },
  // { /* href: '#' + LocationHashEnum.LocationServices, */ label: 'Наши услуги' },
  // { /* href: '#' + LocationHashEnum.LocationPartners, */ label: 'Наши партнеры' },
  // { /* href: '#' + LocationHashEnum.LocationForPartners, */ label: 'Презентация для партнеров' },
  // { /* href: '#' + LocationHashEnum.LocationForRegions, */ label: 'Мы для регионов' },
  // { /* href: '#' + LocationHashEnum.LocationContacts, */ label: 'Контакты' },
  // { /* href: '#' + LocationHashEnum.LocationBlogs, */ label: 'Блоги' },
]

export const siteMenu: { href?: string; label: string }[] = [
  { label: 'Главная', href: '/' },
  { label: 'Политика конфиденциальности', href: '/agreement' }, // ???
]

export const mainActions: { href?: string; label: string }[] = [
  { label: 'Хочу бесплатную консультацию' /* , href: '#OrderConsultationForm' */ },
  { label: 'Хочу купить' /* , href: '#BuyApartmentForm' */ },
  { label: 'Хочу продать' /* , href: '#SellApartmentForm' */ },
]

export const socials: { icon: IconDefinition; label?: string; href: string }[] = [
  // { icon: faEnvelopeSquare, href: 'mailto:mail@march.team' },
  { icon: faFacebookSquare, label: 'Facebook', href: 'https://www.facebook.com/march.realty' },
  { icon: faInstagram, label: 'Instagram', href: 'https://www.instagram.com/march.realty' },
  // { icon: faTwitterSquare, label: 'Twitter', href: 'https://twitter.com/march.realty' },
  { icon: faVk, label: 'VK', href: 'https://vk.com/public209433327' },
]
