/** @module config.build
 *  @description Build management config
 *  @since 2019.09.10, 14:25
 *  @changed 2022.03.27, 19:59
 */

export const isLocalhost = typeof window !== 'undefined' && window.location.host.startsWith('localhost')
export const isBrowser = !!process.browser
export const isDev = !!process.env.NEXT_PUBLIC_DEV
export const isProd = !isDev
export const DEBUG = isDev
export const DEMO_DEBUG = isDev && process.env.NEXT_PUBLIC_DEV === 'DEMO'
export const isDevServer = isDev && process.env.NEXT_PUBLIC_DEV === 'DEVSERVER'

export const version = process.env.version
export const timetag = process.env.timetag
export const timestamp = process.env.timestamp
export const buildTag = process.env.buildTag
