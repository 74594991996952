/** @module config.firebase
 *  @since 2019.09.10, 14:25
 *  @changed 2022.01.22, 18:26
 */

export const apiKey = process.env.apiKey
export const authDomain = process.env.authDomain
export const databaseURL = process.env.databaseURL
export const projectId = process.env.projectId
export const storageBucket = process.env.storageBucket
export const messagingSenderId = process.env.messagingSenderId
export const appId = process.env.appId
export const measurementId = process.env.measurementId
export const location = process.env.location
