/** @module config.api
 *  @since 2022.03.26, 02:39
 *  @changed 2022.03.27, 19:59
 */

import * as build from './build'

export const DEBUG = build.DEBUG

export const apiHost =
  build.isDev || build.isLocalhost
    ? 'http://localhost:5000' // Local flask instance
    : // prettier-ignore
      'https://back.march.team' // Production flask app

export const apiRoot = '/api/v1.0'
export const apiUrlPrefix = apiHost + apiRoot

// Authorization...
export const useApiAuth = false
export const authName = 'api'
export const authPass = 'pusplndvqaivbynv'
// export const apiAuth = 'Basic YXBpOnB1c3BsbmR2cWFpdmJ5bnY=' // as result of `'Basic' + btoa(authName + ':' + authPass)`
