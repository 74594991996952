/** @module Requestor
 *  @since 2021.12.13, 17:05
 *  @changed 2022.04.02, 16:53
 *
 *  NOTE: Module is under development!
 *
 *  TODO: Add a more precise definition of error types.
 *  Eg: 'Unexpected token < in JSON at position 0` => Data parsing error.
 */

// UNUSED: Dependencies for xhrFetch, axiosFetch, superFetch
// import axios, { AxiosRequestConfig } from 'axios'
// import request from 'superagent' // @see https://github.com/visionmedia/superagent

import { makeQuery } from '@helpers/makeQuery'
import config from '@config'
import RequestError from './RequestError'

// // Set default axios headers (UNUSED)...
// axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

export interface TRequestorFetchParams {
  url: string
  method?: 'GET' | 'POST'
  data?: string | Record<string, any>
  responseType?: string // 'json' etc
}

export interface TRequestorParams {
  id?: string
}

// Class Requestor
class Requestor {
  id?: string

  /// Lifecycle...

  constructor(params: TRequestorParams = {}) {
    Object.keys(params).forEach((key) => {
      if (params[key] != null) {
        this[key] = params[key]
      }
    })
  }

  destroy() {
    // TODO
  }

  /// Core data providers...

  parsePlainText(res, text) {
    // const {
    //   // body,
    //   // status,
    //   // ok,
    //   headers,
    // } = res
    // const headerKeys = Array.from(headers.keys())
    // const contentType = headers && headers.get('content-type')
    if (/^\w+:/.test(text)) {
      const chunks = text.split(/^(\w+?):\s*/gm)
      // chunks.shift() // Remove first (empty) chunk
      const params: any = {
        // url: res.url,
        // // data: requestDataString,
        // // method,
        // status,
        // headers,
        // reason: 'ServerError',
      }
      for (let i = 1; i < chunks.length; ) {
        let id = chunks[i++]
        if (id === 'code') {
          id = 'status'
        }
        let val: number | string = chunks[i++].trim()
        if (!isNaN(Number(val))) {
          val = Number(val)
        }
        params[id] = val
      }
      // TODO: Process plain text
      return params
    } else {
      return { text }
    }
  }

  asyncGetData(res) {
    const {
      // body,
      // status,
      // ok,
      headers,
    } = res
    const contentType = headers && headers.get('content-type')
    const isJson = contentType && contentType.includes('application/json')
    if (isJson) {
      return res.json()
    } else {
      return res.text().then((text) => {
        const params = this.parsePlainText(res, text)
        return params
      })
    }
  }

  simpleFetchPromiseCb(params, resolve, reject) {
    // prettier-ignore
    const {
      url,
      method = 'GET',
      data,
      // responseType = 'json',
    } = params
    const headers: any = {
      // Note: Access-Control-Allow-Origin is prohibited from using a wildcard for requests with credentials: 'include'. In such cases, the exact origin must be provided; even if you are using a CORS unblocker extension, the requests will still fail.
      // 'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json',
      // 'Access-Control-Allow-Credentials': 'true',
      // 'Access-Control-Request-Method': 'GET',
      // 'Access-Control-Allow-Origin': '*',
    }
    const fetchParams: any = {
      credentials: 'include',
      // credentials: 'same-origin',
      method,
      headers,
      // redirect: 'follow',
      // referrerPolicy: 'origin',
    }
    let requestUrl = url
    if (data && method === 'GET') {
      // Make url query if data specified
      const prefix = url.includes('?') ? '' : '?' // Add prefix?
      requestUrl += makeQuery(data, { prefix })
    }
    let requestDataString
    if (method === 'POST' && data) {
      // TODO: Use safeStringify
      requestDataString = typeof data !== 'string' ? JSON.stringify(data) : data
      headers['Content-Type'] = 'application/json'
      fetchParams.body = requestDataString
    }
    const { useApiAuth, authName, authPass } = config.api
    if (useApiAuth && authName && authPass) {
      const authData = btoa(authName + ':' + authPass)
      const authStr = 'basic ' + authData
      headers.Authorization = authStr
      // fetchParams.credentials = 'include' // NOTE: Causes cors error for local dev server?
    }
    try {
      const req = fetch(requestUrl, fetchParams) // req is Promise
      // console.log('@:Requestor:simpleFetch: request starting', {
      //   method,
      //   requestUrl,
      //   requestDataString,
      //   fetchParams,
      //   headers,
      //   params,
      //   data,
      //   req,
      // })
      // const promise = // TODO: Store promise!
      req
        .then((res) => {
          // Process json and non-json reponses separately...
          return this.asyncGetData(res)
        })
        .then((result) => {
          // console.log('@:Requestor:simpleFetch: request resolve', {
          //   result,
          // })
          if (result && result.error) {
            let message = 'Серверная ошибка: ' + String(result.error)
            if (result.status === 404 || result.error.includes('NotFound') || result.error.includes('Not Found')) {
              message = 'Запрошенный ресурс не найден на сервере (404)'
            }
            const error = new RequestError({ ...result, message })
            // eslint-disable-next-line no-console
            console.error('@:Requestor:simpleFetch: got plain text server error', error.message, {
              message,
              result,
              error,
              status,
              // method,
              // requestUrl,
              // requestDataString,
              // fetchParams,
              // headers,
              // data,
            })
            // eslint-disable-next-line no-debugger
            debugger
            // return Promise.reject(error)
            throw error
          }
          resolve(result)
        })
        .catch((error) => {
          const { config, request, response = {} } = error
          const { status, message } = error
          if (message === 'Failed to fetch') {
            error = new Error('Невозможно установить сетевое соединение')
          }
          // eslint-disable-next-line no-console
          console.error('@:Requestor:simpleFetch: request failed:', error.message, {
            status,
            config,
            request,
            response,
            message,
            error,
            requestUrl,
            requestDataString,
            url,
            method,
            fetchParams,
            headers,
            params,
          })
          debugger // eslint-disable-line no-debugger
          // TODO: Process empty CORS error (`Failed to fetch`)
          return reject(error)
        })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('@:Requestor:simpleFetch: catch error', error.message, {
        error,
        fetchParams,
        url,
        method,
        params,
      })
      debugger // eslint-disable-line no-debugger
      // throw error
      return reject(error)
    }
  }

  /** Fetch using native `fetch` method */
  simpleFetch(params: TRequestorFetchParams) {
    return new Promise(this.simpleFetchPromiseCb.bind(this, params))
  }

  /* // UNUSED: Other request methods: xhrFetch, axiosFetch, superFetch
   * [>* Fetch using XMLHttpRequest <]
   * xhrFetch(params: TRequestorFetchParams) {
   *   // @see:
   *   // - https://xhr.spec.whatwg.org/
   *   // - https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/send
   *   return new Promise((resolve, reject) => {
   *     const {
   *       url,
   *       method = 'GET',
   *       data, // TODO
   *       // responseType = 'json',
   *     } = params
   *     try {
   *       const xhrRequest = new XMLHttpRequest()
   *       let requestUrl = url
   *       if (data && method === 'GET') {
   *         // Make url query if data specified
   *         const prefix = url.includes('?') ? '' : '?' // Add prefix?
   *         requestUrl += makeQuery(data, { prefix })
   *       }
   *       let requestDataString: string = null
   *       if (method === 'POST' && data) {
   *         // TODO: Use safeStringify
   *         requestDataString = typeof data !== 'string' ? JSON.stringify(data) : data
   *         xhrRequest.setRequestHeader('Content-Type', 'application/json')
   *       }
   *       const { useApiAuth, authName, authPass } = config.api
   *       const async = true
   *       const xhrParams = [
   *         method,
   *         requestUrl,
   *         async,
   *         // authName,
   *         // authPass,
   *       ]
   *       // TODO: Add data (for POST and GET)
   *       if (useApiAuth && authName && authPass) {
   *         xhrParams.push(authName, authPass)
   *       }
   *       // console.log('@:Requestor:xhrFetch: request starting', {
   *       //   url,
   *       //   data,
   *       //   params,
   *       //   requestUrl,
   *       //   requestDataString,
   *       //   xhrParams,
   *       //   xhrRequest,
   *       //   // useApiAuth,
   *       //   // authName,
   *       //   // authPass,
   *       // })
   *       // eslint-disable-next-line prefer-spread
   *       xhrRequest.open.apply(xhrRequest, xhrParams) // (method, url, false, authName, authPass)
   *       xhrRequest.onreadystatechange = function handleXHRRequestStateChange(_event) {
   *         const { readyState, status, responseText } = xhrRequest
   *         // console.log('@:Requestor:handleRequestStateChange', {
   *         //   readyState,
   *         //   status,
   *         //   responseText,
   *         //   event,
   *         //   xhrParams,
   *         //   xhrRequest,
   *         // })
   *         if (readyState === 4 && status === 200) {
   *           // TODO: Check other content types (using `params.responseType`?)
   *           const data = responseText && JSON.parse(responseText)
   *           // console.log('@:Requestor:xhrFetch: request succeed', {
   *           //   data,
   *           //   readyState,
   *           //   status,
   *           //   responseText,
   *           //   event,
   *           //   url,
   *           //   xhrParams,
   *           //   xhrRequest,
   *           // })
   *           resolve(data)
   *         }
   *         // TODO: Check other cases (4**, 5** etc)
   *       }
   *       xhrRequest.send(requestDataString)
   *     } catch (error) {
   *       console.error('@:Requestor:xhrFetch: caught error', error.message, { error, url }) // eslint-disable-line no-console
   *       debugger // eslint-disable-line no-debugger
   *       // throw error
   *       reject(error)
   *     }
   *   })
   * }
   * [>* Fetch data with axios <]
   * axiosFetch(params: TRequestorFetchParams) {
   *   return new Promise((resolve, reject) => {
   *     // prettier-ignore
   *     const {
   *       url,
   *       method = 'GET',
   *         data,
   *       // responseType = 'json',
   *     } = params
   *     let requestUrl = url
   *     if (data && method === 'GET') {
   *       // Make url query if data specified
   *       const prefix = url.includes('?') ? '' : '?' // Add prefix?
   *       requestUrl += makeQuery(data, { prefix })
   *     }
   *     const requestData = method === 'POST' ? data : undefined
   *     const { useApiAuth, authName, authPass } = config.api
   *     const axiosParams: AxiosRequestConfig = {
   *       [> // Available parameters (@see https://github.com/axios/axios#request-config):
   *        * url?: string;
   *        * method?: Method;
   *        * baseURL?: string;
   *        * transformRequest?: AxiosRequestTransformer | AxiosRequestTransformer[];
   *        * transformResponse?: AxiosResponseTransformer | AxiosResponseTransformer[];
   *        * headers?: AxiosRequestHeaders;
   *        * params?: any;
   *        * paramsSerializer?: (params: any) => string;
   *        * data?: D;
   *        * timeout?: number;
   *        * timeoutErrorMessage?: string;
   *        * withCredentials?: boolean;
   *        * adapter?: AxiosAdapter;
   *        * auth?: AxiosBasicCredentials;
   *        * responseType?: ResponseType;
   *        * xsrfCookieName?: string;
   *        * xsrfHeaderName?: string;
   *        * onUploadProgress?: (progressEvent: any) => void;
   *        * onDownloadProgress?: (progressEvent: any) => void;
   *        * maxContentLength?: number;
   *        * validateStatus?: ((status: number) => boolean) | null;
   *        * maxBodyLength?: number;
   *        * maxRedirects?: number;
   *        * socketPath?: string | null;
   *        * httpAgent?: any;
   *        * httpsAgent?: any;
   *        * proxy?: AxiosProxyConfig | false;
   *        * cancelToken?: CancelToken;
   *        * decompress?: boolean;
   *        * transitional?: TransitionalOptions;
   *        * signal?: AbortSignal;
   *        * insecureHTTPParser?: boolean;
   *        <]
   *       method,
   *       url: requestUrl,
   *       data: requestData,
   *       // withCredentials: true,
   *       headers: {
   *         'Content-Type': 'application/json',
   *         // // NOTE: Use only for cors issues...
   *         // 'Access-Control-Allow-Credentials': 'true',
   *         // 'Access-Control-Allow-Origin': '*',
   *       },
   *     }
   *     if (useApiAuth && authName && authPass) {
   *       axiosParams.withCredentials = true
   *       axiosParams.auth = {
   *         username: authName,
   *         password: authPass,
   *       }
   *     }
   *     // console.log('@:Requestor:axiosFetch: request starting', {
   *     //   method,
   *     //   requestUrl,
   *     //   requestData,
   *     //   params,
   *     //   axiosParams,
   *     // })
   *     try {
   *       axios(axiosParams)
   *         .then((res) => {
   *           const { data } = res
   *           // console.log('@:Requestor:axiosFetch: request succeed', {
   *           //   data,
   *           //   res,
   *           //   method,
   *           //   requestUrl,
   *           //   requestData,
   *           //   params,
   *           //   axiosParams,
   *           // })
   *           resolve(data)
   *         })
   *         .catch((error) => {
   *           const { config, request, response = {} } = error
   *           const { status } = response
   *           // eslint-disable-next-line no-console
   *           console.error('@:Requestor:axiosFetch: request failed', error.message, {
   *             status,
   *             config,
   *             request,
   *             response,
   *             error,
   *             method,
   *             requestUrl,
   *             requestData,
   *             params,
   *             axiosParams,
   *           })
   *           debugger // eslint-disable-line no-debugger
   *           reject(error)
   *         })
   *     } catch (error) {
   *       // eslint-disable-next-line no-console
   *       console.error('@:Requestor:axiosFetch: catch error', error.message, {
   *         error,
   *         method,
   *         requestUrl,
   *         requestData,
   *         params,
   *         axiosParams,
   *       })
   *       debugger // eslint-disable-line no-debugger
   *       // throw error
   *       reject(error)
   *     }
   *   })
   * }
   * [>* Fetch data with superagent <]
   * superFetch(params: TRequestorFetchParams) {
   *   return new Promise((resolve, reject) => {
   *     // prettier-ignore
   *     const {
   *       url,
   *       method = 'GET',
   *       data,
   *       // responseType = 'json',
   *     } = params
   *     const { useApiAuth, authName, authPass } = config.api
   *     let requestUrl = url
   *     if (data && method === 'GET') {
   *       // Make url query if data specified
   *       // throw new Error('makeQuery not yet implemented')
   *       const prefix = url.includes('?') ? '' : '?' // Add prefix?
   *       requestUrl += makeQuery(data, { prefix })
   *     }
   *     let requestData
   *     if (method === 'POST' && data) {
   *       // TODO: Use safeStringify
   *       requestData = data
   *       // xhrRequest.setRequestHeader('Content-Type', 'application/json')
   *     }
   *     try {
   *       const req = request(method, requestUrl)
   *       if (params.responseType) {
   *         req.responseType(params.responseType)
   *       }
   *       // req.timeout({
   *       //   response: responseTimeout,
   *       //   deadline: deadlineTimeout,
   *       // });
   *       // this._requestStarted(req);
   *       if (requestData) {
   *         // Send post data if specified
   *         req.send(requestData)
   *       }
   *       if (useApiAuth && authName && authPass) {
   *         const authData = btoa(authName + ':' + authPass)
   *         const authStr = 'basic ' + authData
   *         req.set('Authorization', authStr)
   *         // req.auth(authName, authPass)
   *         req.withCredentials()
   *       }
   *       // console.log('@:Requestor:superFetch: request starting', {
   *       //   requestUrl,
   *       //   requestData,
   *       //   url,
   *       //   data,
   *       //   method,
   *       //   params,
   *       //   req,
   *       // })
   *       // TODO: Store req in reuqests queue and use produced promise?
   *       // const promise =
   *       req
   *         .then((res) => {
   *           const { body } = res
   *           // console.log('@:Requestor:superFetch: request succeed', {
   *           //   body,
   *           //   res,
   *           //   url,
   *           //   method,
   *           //   params,
   *           // })
   *           resolve(body)
   *         })
   *         .catch((error) => {
   *           const { config, request, response = {} } = error
   *           const { status } = error
   *           // eslint-disable-next-line no-console
   *           console.error('@:Requestor:superFetch: request failed', error.message, {
   *             status,
   *             config,
   *             request,
   *             response,
   *             error,
   *             url,
   *             method,
   *             params,
   *           })
   *           debugger // eslint-disable-line no-debugger
   *           reject(error)
   *         })
   *     } catch (error) {
   *       // eslint-disable-next-line no-console
   *       console.error('@:Requestor:superFetch: catch error', error.message, {
   *         error,
   *         url,
   *         method,
   *         params,
   *       })
   *       debugger // eslint-disable-line no-debugger
   *       // throw error
   *       reject(error)
   *     }
   *   })
   * }
   */

  /// Public data fetch interface...

  fetch(params: TRequestorFetchParams) {
    // console.log('@:Requestor:fetch', params)
    return this.simpleFetch(params)
  }
}

// TODO: export default compose(...)(Requestor)
export default Requestor
